<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import constants from '~/config/constants'

import { useAuth } from '~/stores/authentication'

const AuthStore = useAuth()

const Router = useRouter()

const Route = useRoute()

const user = ref({
    displayName: 'asd',
    photoURL: 'asd',
})

// Logout
const logout = async () => {
    const tokenCookie = useCookie(constants.authToken)

    tokenCookie.value = null

    if (Route.name === 'index') {
        location.reload()
    } else {
        location.pathname = '/'
    }
}
</script>

<template>
    <Menu as="div" class="relative inline-block text-left font-Pathway">
        <div v-if="user && user.photoURL && user.displayName">
            <MenuButton
                class="flex items-center justify-center w-full overflow-hidden text-sm font-medium text-white bg-black border-2 border-gray-200 rounded-full bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
                <img
                    :src="
                        AuthStore.userProfile && AuthStore.userProfile.avatar
                            ? AuthStore.userProfile.avatar.thumbnail
                            : $assets.black.user
                    "
                    class="w-8 h-8 lg:h-10 lg:w-10"
                    :alt="user.displayName"
                />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                class="absolute right-0 mt-2 w-36 origin-top-right border rounded-md bg-[#232426] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style="border: 1px solid rgb(55, 58, 64)"
            >
                <div class="px-1 py-1">
                    <MenuItem v-slot="{ active }">
                        <button
                            @click="() => Router.push('/perfil')"
                            :class="[
                                active ? 'bg-opacity-25 bg-[#5c5f66] text-white' : 'text-stone-100',
                                'group flex w-full items-center rounded-md px-2 py-2 text-sm gap-1',
                            ]"
                            class="flex gap-2"
                        >
                            <UnoIcon class="i-mdi:account" />
                            Perfil
                        </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <button
                            @click="logout"
                            :class="[
                                active ? 'bg-opacity-25 bg-[#5c5f66] text-white' : 'text-stone-100',
                                'group  w-full items-center rounded-md px-2 py-2 text-sm',
                            ]"
                            class="flex gap-2"
                        >
                            <UnoIcon class="i-mdi:logout" />
                            Cerrar sesión
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
