<script setup lang="ts">
import { useAuth } from '~/stores/authentication'

const head = ref(null) as Ref<HTMLElement> | Ref<null>

const navbar = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'Series',
        link: '/series',
    },
    {
        title: 'Artistas',
        link: '/artistas',
    },
    {
        title: 'Proyectos Especiales',
        link: '/proyectos-especiales',
    },
    {
        title: 'Exhibiciones',
        link: '/exhibiciones',
    },
    {
        title: 'Nosotros',
        link: '/nosotros',
    },
]

const AuthStore = useAuth()

const userLoggedIn = computed(() => AuthStore.isAuth)
</script>

<template>
    <transition name="slide-down">
        <header ref="head" class="header">
            <section class="menu">
                <h1 class="menu__image-h1">
                    <NuxtLink no-prefetch to="/" class="menu__image">
                        <picture class="block">
                            <source media="(min-width: 600px)" :srcset="$assets.logo.default" />
                            <img
                                loading="lazy"
                                class="logo"
                                :src="$assets.logo.default"
                                alt="logo"
                                width="242px"
                                height="28px"
                            />
                        </picture>
                    </NuxtLink>
                </h1>
                <div class="menu__right">
                    <HeaderSearch class="menu__right-search" />
                    <NuxtLink no-prefetch to="/login" class="menu__right-join" v-if="!userLoggedIn"
                        >INGRESAR</NuxtLink
                    >
                    <HeaderUser v-else class="menu__right-user" />
                    <HeaderMenu class="menu__right-icon" :data="navbar" />
                </div>
            </section>
            <section class="navbar">
                <ul>
                    <li v-for="item in navbar">
                        <NuxtLink no-prefetch :to="item.link">{{ item.title }}</NuxtLink>
                    </li>
                </ul>
            </section>
        </header>
    </transition>
</template>

<style lang="postcss">
body {
    --header-height-mobile: 60px;
    --header-height-md: 122px;
    --header-height: 125px;
}

.header {
    @apply w-screen bg-white text-black font-Pathway top-0 fixed  z-[100] border-b border-b-black h-[--header-height-mobile] md:h-[--header-height-md] lg:h-[--header-height];
    .menu {
        @apply flex justify-between container;
        &__image-h1 {
            @apply w-32 flex items-center flex-none;
        }
        &__image {
            @apply block p-1 max-w-max mr-auto;
            img {
                @apply w-20 md:w-36;
            }
        }
        &__right {
            @apply flex items-center gap-4 lg:gap-10;
            &-search {
                @apply order-2 lg:order-1;
            }
            &-join {
                @apply bg-[#0A0A0A] px-3 py-2 text-white font-semibold text-xs lg:text-base order-1 lg:order-2;
            }
            &-icon {
                @apply md:hidden order-3;
            }
            &-user {
                @apply block order-4;
            }
        }
    }
    .navbar {
        @apply container mb-3 hidden md:flex;
        ul {
            @apply flex items-center justify-between w-full;
            li {
                a {
                    @apply text-[#1E1E1E] font-bold lg:text-xl xl:text-2xl 2xl:text-[28px] uppercase hover:underline underline-offset-8;
                }
            }
        }
    }
}

.slide-down-enter-active,
.slide-down-leave-active {
    @apply transition-all duration-200 ease-linear;
}

.slide-down-enter-from,
.slide-down-leave-to {
    @apply -translate-y-full opacity-0;
}
</style>
