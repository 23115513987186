<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PropType } from 'vue'

const props = defineProps({
    data: {
        type: Array as PropType<
            {
                title: string
                link: string
            }[]
        >,
        required: true,
    },
})

const socials = ref([
    {
        icon: 'i-mdi:facebook',
        link: 'https://www.facebook.com',
    },
    {
        icon: 'i-ph-instagram-logo-fill',
        link: 'https://www.instagram.com',
    },
    {
        icon: 'i-mdi:linkedin',
        link: 'https://www.linkedin.com',
    },
    {
        icon: 'i-mdi:twitter',
        link: 'https://twitter.com',
    },
    {
        icon: 'i-mdi:youtube',
        link: 'https://www.youtube.com',
    },
])
</script>

<template>
    <Popover v-slot="{ open }" class="flex-none">
        <PopoverButton
            :class="open ? 'rounded-t-md shadow-2xl' : 'rounded-md'"
            class="flex items-center justify-center text-base font-medium text-black group"
        >
            <div class="flex items-center text-black text-3xl">
                <img :src="$assets.black.menu" alt="menu" />
            </div>
        </PopoverButton>

        <transition name="mobile-menu">
            <PopoverPanel
                class="absolute z-20 flex flex-col inset-0 pt-5 h-screen shadow-lg bg-white text-black"
                v-slot="{ close }"
            >
                <div
                    class="font-Pathway font-bold text-[25px] flex text-black justify-between items-center px-4 border-b border-black mb-5 pb-4"
                >
                    Menú
                    <div @click="close" class="flex items-center text-black text-2xl">
                        <UnoIcon class="i-mdi:close" />
                    </div>
                </div>
                <div class="h-full space-y-10 mt-4 overflow-y-auto text-xl px-4">
                    <template v-for="item in data">
                        <div
                            as="div"
                            class="flex items-start text-[26px] w-full font-bold uppercase text-left"
                        >
                            <NuxtLink :to="item.link" @click="close">{{ item.title }}</NuxtLink>
                        </div>
                    </template>
                    <div>
                        <p class="font-Pathway font-bold text-[20px] pt-5 mb-4">Síguenos en nuestras redes</p>
                        <UtilsSocials :data="socials" type="big" />
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<style lang="postcss">
.mobile-menu-enter-active {
    @apply transition duration-200;
}
.mobile-menu-enter-from {
    @apply -translate-x-1/2 opacity-0;
}
.mobile-menu-enter-to {
    @apply translate-x-0 opacity-100;
}
.mobile-menu-leave-active {
    @apply transition duration-150;
}
.mobile-menu-leave-from {
    @apply translate-x-0 opacity-100;
}
.mobile-menu-leave-to {
    @apply -translate-x-1/2 opacity-0;
}

.fade-enter-active,
.fade-leave-active {
    @apply transition duration-200;
}
.fade-leave-to,
.fade-enter-from {
    @apply opacity-5;
}
</style>
