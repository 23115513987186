<script lang="ts" setup>
import { useModal } from '~/stores/modal'

const ModalStore = useModal()

const nuxtApp = useNuxtApp()

const loading = ref(false)

let startTimeout = null as null | NodeJS.Timeout

nuxtApp.hook('page:start', () => {
    startTimeout = setTimeout(() => {
        loading.value = true
    }, 100)
})

nuxtApp.hook('page:finish', () => {
    if (startTimeout) {
        clearTimeout(startTimeout)
        startTimeout = null
    }

    if (loading.value) setTimeout(() => (loading.value = false), 300)
})
</script>
<template>
    <div class="app">
        <Header class="app__header" />
        <div class="app__slot">
            <slot />
        </div>
        <Teleport to="body"
            ><UtilsLayoutModal
                v-if="ModalStore.modal !== null"
                :modal="ModalStore.modal"
                @close="() => ModalStore.setModal(null)"
            />
            <transition name="fade"> <UtilsFullScreenLoader v-if="loading" /></transition>
        </Teleport>
        <Footer class="app__footer" />
    </div>
</template>

<style lang="postcss">
.app {
    @apply flex flex-col min-h-screen;
    &__slot {
        @apply mt-[--header-height-mobile] md:mt-[--header-height-md] lg:mt-[--header-height] flex-1;
    }
    &__footer {
        @apply mt-auto;
    }
}
</style>
