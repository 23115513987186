<script setup lang="ts">
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Array as PropType<Array<Models.CollectionResource | Models.ArtistResource>>,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    mode: {
        type: String as PropType<'square' | 'circle'>,
        required: true,
    },
    mobile: {
        type: Boolean,
        default: false,
    },
})

const styles = computed(() => {
    return {
        container: !props.mobile ? 'p-2 bg-white' : 'pt-3',
        p: !props.mobile ? 'px-3' : '',
        item: !props.mobile ? 'p-3' : 'py-3',
    }
})
</script>

<template>
    <div class="overflow-hidden">
        <div class="relative" :class="styles.container">
            <p class="py-2 text-xs font-semibold text-[#969696]" :class="styles.p">{{ props.name }}</p>
            <ul v-if="props.data.length">
                <li v-for="(item, key) in props.data">
                    <NuxtLink
                        no-prefetch
                        :key="key"
                        class="flex items-center gap-3 transition duration-150 hover:bg-gray-100"
                        :class="styles.item"
                        :to="`${item.type === 'Artista' ? '/artistas/' : '/series/'}${item.slug}`"
                    >
                        <picture
                            class="flex-none overflow-hidden bg-gray-100 h-[25px] w-[25px]"
                            :class="mode === 'circle' ? 'rounded-full' : ''"
                        >
                            <img
                                loading="lazy"
                                :src="item.type === 'Artista' ? item.avatar : item.cover"
                                :alt="item.type === 'Artista' ? item.name : item.title"
                                class="object-cover w-full h-full"
                                width="56px"
                                height="56px"
                                :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                            />
                        </picture>
                        <div class="flex flex-col text-base">
                            <span class="font-bold line-clamp-2">{{
                                item.type === 'Artista' ? item.name : item.title
                            }}</span>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
            <p v-else class="px-3 text-xs text-gray-500">
                No hay {{ props.name.toLocaleLowerCase() }} disponibles...
            </p>
        </div>
    </div>
</template>

<style scoped></style>
