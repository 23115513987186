<template>
    <transition name="fade">
        <div ref="modal" class="layout-modal">
            <div ref="modal" class="layout-modal__wrapper">
                <LazyModalDefault
                    v-if="props.modal && props.modal.name === 'ModalDefault'"
                    :info="props.modal.info"
                    class="layout-modal__component"
                    @close="() => mount(false)"
                >
                <button type="button" class="layout-modal__close-button">
                    <UnoIcon class="i-mdi:close" @click="() => mount(false)" />
                </button>
                </LazyModalDefault>
                <LazyModalFeaturedWork
                    v-if="props.modal && props.modal.name === 'ModalFeaturedWork'"
                    :info="props.modal.info"
                    class="layout-modal__component"
                    @close="() => mount(false)"
                >
                <button type="button" class="layout-modal__close-button">
                    <UnoIcon class="i-mdi:close" @click="() => mount(false)" />
                </button>
                </LazyModalFeaturedWork>
            </div>

            <div class="modal-overlay" @click="() => mount(false, 'overlay')" />
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'

const props = defineProps({
    modal: Object as PropType<{
        info: object
        name: string
        onOpen: () => null
        onClose: (target?: 'overlay') => null
    } | null>,
})

const emit = defineEmits(['close'])

const modal = ref<null | HTMLDivElement>(null)

const Route = useRoute()

const mount = async (mount: boolean, target?: 'overlay') => {
    if (!mount || !props.modal) {
        emit('close')

        if (props.modal && props.modal.onClose) props.modal.onClose(target)

        await nextTick()
    }
}

document.onkeydown = function (e) {
    if (e.key === 'Escape') {
        mount(false, 'overlay')
    }
}

onMounted(async () => {
    await nextTick()

    const { height } = useWindowSize()

    props.modal?.onOpen && props.modal.onOpen()

    let topPos = ''

    topPos = scrollY + height.value * 0.5 + 'px'

    modal.value && (modal.value.style.top = topPos)
})

watch(Route, () => mount(false), {
    deep: true,
})


</script>

<style lang="postcss" scoped>
.layout-modal {
    @apply absolute inset-0 z-[999];

    & .modal-overlay {
        @apply fixed inset-0 bg-black bg-opacity-50 z-10;
    }

    &__wrapper {
        @apply absolute z-20 min-h-min left-4 right-4;
        @screen md {
            @apply max-w-4xl w-full left-1/2 -translate-x-1/2 -translate-y-1/2;
        }
    }
    &__wrapper-full-page {
        @apply overflow-hidden inset-0 h-screen w-screen absolute z-20;
    }

    &__close-button {
        @apply absolute w-8 h-8 rounded-full flex items-center justify-center right-2 top-1.5 bg-white z-[110];
    }
    &__component-full-page {
        @apply h-screen w-screen;
    }
    &__component {
        @apply relative overflow-hidden flex-1 mx-auto z-[100] md:max-w-sm;
    }
    @screen md {
        &__component {
            max-width: 728px;
        }
    }
    @screen lg {
        &__component {
            max-width: 768px;
            /*  max-height: calc(100vh - theme('spacing.40')); */
        }
    }
}
</style>
