<script setup lang="ts">
import { Popover, PopoverPanel } from '@headlessui/vue'
import { breakpointsTailwind, useBreakpoints, onClickOutside } from '@vueuse/core'

import { Models } from '@/types/models'

const breakpoints = useBreakpoints(breakpointsTailwind)

const greaterOrEqualThanMd = breakpoints.greaterOrEqual('md')

const { SEARCH } = useContentService()

const input = ref<HTMLInputElement>()

const query = ref<string>('')

const suggestionResources = reactive<{
    artists?: Models.ArtistResource[]
    collections?: Models.CollectionResource[]
}>({
    artists: [],
    collections: [],
})

const searchContext = ref<null | HTMLDivElement>(null)
const suggestionDebounce = ref<null | NodeJS.Timeout>(null)
const showSuggestions = ref(false)
const isLoading = ref(false)

onClickOutside(searchContext, () => {
    if (suggestionDebounce.value) clearTimeout(suggestionDebounce.value)
    showSuggestions.value = false
    isLoading.value = false
    query.value = ''
    suggestionResources.artists = []
    suggestionResources.collections = []
})

watch(query, (query: string) => {
    if (query.length > 2) {
        showSuggestions.value = true

        isLoading.value = true

        if (suggestionDebounce.value) clearTimeout(suggestionDebounce.value)

        suggestionDebounce.value = setTimeout(async () => {
            suggestionResources.artists = []

            suggestionResources.collections = []

            try {
                const result = await SEARCH({ query })

                suggestionResources.artists = result.artists.data

                suggestionResources.collections = result.collections.data
            } catch (e) {
                console.log('Error on search: ', { e })
            }
            isLoading.value = false
        }, 800)
    } else {
        isLoading.value = false
        if (suggestionDebounce.value) clearTimeout(suggestionDebounce.value)
    }
})

const router = useRouter()

const onEnter = () => {
    if (suggestionDebounce.value) clearTimeout(suggestionDebounce.value)
    showSuggestions.value = false
    suggestionResources.artists = []
    suggestionResources.collections = []
    isLoading.value = false

    if (query.value.length) {
        router.push({ path: '/search', query: { q: query.value } })
        query.value = ''
    }
}

const route = useRoute()
</script>

<template>
    <div ref="searchContext">
        <Popover class="md:relative">
            <div class="relative hidden md:flex">
                <input
                    ref="input"
                    name="search"
                    aria-label="Buscar"
                    v-model="query"
                    placeholder="BUSCAR..."
                    class="h-10 pt-3 pl-0 pr-10 text-lg transition border-t-0 border-b-2 w-72 lg:w-60 xl:w-72 placeholder:text-gray-600 focus:placeholder:text-black focus:border-black focus:ring-0 border-x-0"
                    type="text"
                    @keydown.enter="onEnter"
                    @click="suggestions = true"
                />
                <button
                    type="button"
                    @click="onEnter"
                    class="absolute text-lg translate-y-1/2 -top-0 right-1 i-carbon:search"
                >
                    <UnoIcon />
                </button>
            </div>
            <button
                type="button"
                @click="showSuggestions = true"
                class="flex items-center self-center pb-1 border-b border-black cursor-pointer md:hidden"
            >
                <UnoIcon class="text-xl lg:text-2xl i-carbon:search" />
            </button>

            <transition
                v-if="greaterOrEqualThanMd"
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
            >
                <PopoverPanel v-if="showSuggestions" static class="popOver">
                    <template v-if="!isLoading">
                        <HeaderSearchRecomendations
                            :data="suggestionResources.artists"
                            name="ARTISTAS"
                            mode="circle"
                        />
                        <hr />
                        <HeaderSearchRecomendations
                            :data="suggestionResources.collections"
                            name="COLECCIONES"
                            mode="square"
                        />
                    </template>
                    <div v-else class="flex items-center justify-center bg-white h-28">
                        <UtilsLoader class="w-10 h-10 text-gray-500" />
                    </div>
                </PopoverPanel>
            </transition>

            <transition
                v-else
                enter-active-class="transition duration-200 slide-right"
                enter-from-class="translate-x-1 opacity-0"
                enter-to-class="translate-x-0 opacity-100"
                leave-active-class="transition duration-150 slide-left"
                leave-from-class="translate-x-0 opacity-100"
                leave-to-class="translate-x-1 opacity-0"
            >
                <PopoverPanel
                    v-if="showSuggestions"
                    static
                    class="absolute top-0 bottom-0 left-0 right-0 z-10 block w-screen h-full min-h-screen bg-white"
                >
                    <div class="flex w-screen overflow-y-auto text-black">
                        <div class="w-screen">
                            <div class="flex items-center justify-between gap-5 text-xl uppercase">
                                <div class="relative w-full">
                                    <input
                                        v-model="query"
                                        placeholder="Buscar..."
                                        class="font-Pathway w-full text-[21px] font-semibold bg-white outline-none border-none focus:border-none focus:outline-none focus:appearance-none focus:ring-0 ring-0 h-16 placeholder:text-[#8F8F8F] px-12"
                                        type="text"
                                        @keydown.enter="onEnter"
                                    />
                                    <UnoIcon
                                        class="absolute text-2xl translate-y-1/2 top-1 left-2 i-carbon:search"
                                    />
                                    <UnoIcon
                                        class="absolute text-2xl translate-y-1/2 cursor-pointer top-1 right-2 i-mdi:close"
                                        @click="showSuggestions = false"
                                    />
                                </div>
                            </div>
                            <hr class="bg-black border-black" />
                            <template v-if="!isLoading"
                                ><HeaderSearchRecomendations
                                    :data="suggestionResources.artists"
                                    name="ARTISTAS"
                                    mode="circle"
                                    :mobile="true"
                                    class="px-5"
                                />
                                <hr class="mx-3 mt-4 bg-black border-black" />
                                <HeaderSearchRecomendations
                                    :data="suggestionResources.collections"
                                    name="COLECCIONES"
                                    :mobile="true"
                                    mode="square"
                                    class="px-5"
                                />
                            </template>
                            <div v-else class="flex items-center justify-center bg-white h-28">
                                <UtilsLoader class="w-10 h-10 text-gray-500" />
                            </div>
                        </div>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </div>
</template>

<style lang="postcss" scoped>
.popOver {
    @apply absolute z-10 w-full px-4 mt-0 transform sm:px-0 shadow-lg;
    /* add border-t to hr with a padding to the border */
    hr {
        @apply border-t px-2 mx-2 border-black;
    }
}
</style>
