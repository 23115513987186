<script setup lang="ts">
const socials = ref([
    {
        icon: 'i-mdi:facebook',
        link: 'https://www.facebook.com/share/mEcZqD8ocyKykhNg/?mibextid=sCpJLy',
    },
    {
        icon: 'i-ph-instagram-logo-fill',
        link: 'https://www.instagram.com/milenio_arte?igsh=dGF1anl5d2ZiNHp2',
    },
    {
        icon: 'i-mdi:youtube',
        link: 'https://www.youtube.com/user/MILENIO',
    },
])

const currentYear = ref(new Date().getFullYear().toString())
</script>

<template>
    <footer class="footer">
        <div class="footer__wrapper">
            <UtilsSocials :data="socials" />
            <div class="footer__brand">
                <a href="https://milenio.com" target="_blank" rel="nofollow">MILENIO.COM</a>
                <span>DERECHOS RESERVADOS ©GRUPO MILENIO {{ currentYear }}</span>
                <a href="https://www.milenio.com/aviso-de-privacidad" target="_blank" rel="nofollow">AVISO DE PRIVACIDAD</a>
            </div>
        </div> 
    </footer>
</template>

<style lang="postcss" scoped>
.footer {
    @apply bg-white border-t border-black py-5;
    &__wrapper {
        @apply flex flex-col md:flex-row items-center gap-y-3 justify-center md:justify-between container;
    }
    &__brand {
        @apply space-x-1 text-[#060606] text-xs font-Inter font-medium text-center;
        a {
            @apply underline;
        }
        a:last-child {
            @apply font-bold;
        }
    }
}
</style>
